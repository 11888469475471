const defaultOrder = `_id 
                            reference
                            cartId
                            totalValueWithFees
                            status 
                                coupon
                                couponSavings
                                totalValueWithSavings
                            payment {
                                method
                                updatedAt
                                invoiceId
                                billingUrl
                                pixQrCode
                                pixQrCodeText
                                bankSlipTypeableLine
                                bankSlipBarcodeData
                                bankSlipImg
                                status
                                dueDate
                            }
                            shipment{status, deadlineInDays, value}
                            orderUser {
                                address {
                                    city {
                                        name stateId ibgeCode latitude longitude
                                    }
                                    state {
                                        name acronym ibgeCode countryId
                                    }
                                    neighborhoodName location locationNumber locationNotes postalCode latitude longitude isDefault updatedAt
                                }
                            }
                            company {
                                address {
                                    city {
                                        name stateId ibgeCode latitude longitude
                                    }
                                    state {
                                        name acronym ibgeCode countryId
                                    }
                                    neighborhoodName location locationNumber locationNotes postalCode latitude longitude isDefault updatedAt
                                }
                            }
                            createdAt updatedAt totalValue averageValue totalQuantity
                            integrations {
                                integrationType
                                orderSellerId
                                items {
                                    price 
                                    total 
                                    quantity
                                    backorderQuantity
                                    isWithdraw
                                    product { 
                                        _id 
                                        name 
                                        thumbnail 
                                        manufacturerName
                                        leadTime
                                    }
                                }
                                status,
                                deadlineInDays,
                                backorderDeadlineInDays
                            }`
export const makeOrderMutation = `mutation ($cartId: String!, $paymentToken: String, $method: String, $months: Int, $totalValueWithFees: Float) {
                        makeOrder(cartId: $cartId, paymentToken: $paymentToken, method: $method, months: $months, totalValueWithFees: $totalValueWithFees) {
                            success 
                            message 
                            createdAt 
                            updatedAt 
                            order {${defaultOrder}}
                        }
                    }`;

export const makePartnerOrderMutation = `mutation ($cartId: String!) {
                        makePartnerOrder(cartId: $cartId) {
                            success 
                            message 
                            createdAt 
                            updatedAt 
                            order {${defaultOrder}}
                        }
                    }`;

export const getLastOrdersQuery = `query ($start: Float!, $end: Float) {
                        getOrders(start: $start, end: $end) {${defaultOrder}}
                    }`;
