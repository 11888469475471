export const getAllManufacturerSitePagesQuery = `
    query {
        getAllManufacturerSitePages {
            manufacturer {
                _id
                name
            }
            logoUrl
            _id
        }
    }
`;

export const getManufacturerSitePageByManufacturerQuery = `
    query getManufacturerSitePageByManufacturer($manufacturerId: String!) {
        getManufacturerSitePageByManufacturer(manufacturerId: $manufacturerId) {
            _id
            banners { _id url deviceType isActive }
            mainColor logoUrl videoUrl presentationText
            manufacturer { _id name }
            slug
            popup { delay link imageUrl }
            highlightProducts { 
                _id manufacturerName manufacturerId manufacturerReference manufacturerCode price promoPrice stock leadTime isActive isWithdraw unitCommercialName mainName name complement thumbnail stars isRestrictedDelivery
            }
            isActive isRemoved createdAt updatedAt 
        }
    }
`;