import React, {useEffect} from "react";
import TkHeader from "../../components/particles/TkHeader";
import TkDivisionsTop from "../../components/product/TkDivisionsTop";
import TkNewsLetter from "../../components/user/TkNewsLetter";
import TkFooter from "../../components/particles/TkFooter";
import TkHeadSEO from "../../components/particles/TkHeadSEO";
import TkBreadcrumb from "../../components/particles/TkBreadcrumb";
import TkLazyImage from "../../components/particles/TkLazyImage";
import {smoothScroll} from "../../utils/utils";
import "./styles.scss";
import {useTkMedia} from "../../context/TkContext";
import TkYoutubeLazyPlayer from "../../components/particles/TkYoutubeLazyPlayer";

export const tk_route_about = "/quem-somos";
const s3Bucket = "https://teky.s3.sa-east-1.amazonaws.com/plenobras/institucional/"
const videoPleno = "https://youtu.be/U1kcAPancaE"

const TkAboutView: React.FC = () => {
    useEffect(() => smoothScroll(), []);
    const {isMediaSm} = useTkMedia();

    return <>
        <TkHeadSEO
            title="Quem Somos | Plenobras"
            description="Saiba mais sobre a Plenobras, o que fazemos, qual a nossa missão e valores"
        />

        <TkHeader/>
        <div className="TkAboutContainer">
            <TkDivisionsTop/>

            <div className="TkAboutContainer__textContainer">
                <TkBreadcrumb
                    list={[
                        {
                            url: tk_route_about,
                            label: "Quem somos",
                        },
                    ]}
                />

                <div className="TkAboutContent">
                    <div className="TkAboutContent__text">
                        <div>
                            <strong>
                  <span className="TkInstitucional-pleno">
                    Nossa história começou a ser escrita em junho de 1993, pelas mãos dos nossos fundadores, Gerson Salles e Julio
                    Cosme Rosa, que realizaram a primeira venda da Plenobras aqui mesmo, em nossa loja de Porto Alegre, Rio
                    Grande do Sul, onde tudo começou e ganhou significado.
                  </span>
                            </strong>
                        </div>
                        {isMediaSm ? <> <TkYoutubeLazyPlayer videoUrl={videoPleno}/></> : <></>}
                        <div>
                            <br/><br/>
                            <span className="TkInstitucional-pleno">
                  Hoje, somos cerca de 200 pessoas, atuando em todas as áreas da Plenobras. Somos os profissionais do atendimento
                  comercial, da logística, da administração, da tecnologia e de recursos humanos. Somos os consultores técnicos que
                  buscam sempre oferecer às melhores soluções para os projetos de nossos clientes.
              </span>
                            <br/><br/>
                            <span className="TkInstitucional-pleno">
                Somos pessoas comprometidas, engajadas e focadas na excelência do atendimento aos nossos clientes.
              </span>
                            <br/><br/>
                            <span className="TkInstitucional-pleno">
                Somos os condutores de uma frota própria, levando a qualidade dos nossos produtos no menor tempo.
              </span>
                            <br/><br/>
                            <span className="TkInstitucional-pleno">
                Somos os colaboradores por trás da tela, que zelam pela comunicação, infraestrutura e sistemas de venda online, que nos
                mantêm conectados 24 horas por dia com nossos clientes e parceiros. Somos o resultado de todas as conexões da
                Plenobras, que unem colaboradores, fornecedores e clientes em torno do crescimento horizontal e permanente.
              </span>
                        </div>
                        {!isMediaSm ? <> <TkYoutubeLazyPlayer videoUrl={videoPleno}/></> : <></>}
                    </div>
                </div>
                <div>
                    <TkLazyImage src={s3Bucket + "Norteador1.svg"} className="TkAboutContent__img"></TkLazyImage>
                    <div className="TkAboutContent__grid">
                        <div className="TkAboutContent__iconName">Missão</div>
                        <TkLazyImage src={s3Bucket + "Icon1.svg"} className="TkAboutContent__icon"
                                     height={114}></TkLazyImage>
                        <div className="TkAboutContent__description">
                            <br/>
                            <span>Fornecer soluções de qualidade para instalação, modernização e manutenção:</span>
                            <br/>
                            <span>industrial, comercial, da construção e de infraestrutura, com agilidade em tudo,</span>
                            <br/>
                            <span>excelência no atendimento e no relacionamento com os profissionais e clientes do mercado empresarial</span>
                        </div>
                    </div>
                    <div className="TkAboutContent__grid">
                        <div className="TkAboutContent__iconName">Valores</div>
                        <TkLazyImage src={s3Bucket + "Icon3.svg"} className="TkAboutContent__icon"
                                     height={114}></TkLazyImage>
                        <div className="TkAboutContent__description">
                            <br/>
                            <span>• Comprometimento com os clientes; <br/>
                  • Busca constante pela qualidade e inovação; <br/>
                  • Empreendedorismo com responsabilidade; <br/>
                  • Treinamento e desenvolvimento de pessoas; <br/>
                  • Espírito de equipe; <br/>
                  • Parcerias construídas a partir do profissionalismo e dos relacionamentos; <br/>
                  • Rigoroso cumprimento dos compromissos assumidos; <br/>
                  • Rentabilidade como meio de perpetuação e crescimento do negócio.</span>
                        </div>
                    </div>
                    <div className="TkAboutContent__grid">
                        <div className="TkAboutContent__iconName">Visão</div>
                        <TkLazyImage src={s3Bucket + "Icon2.svg"} className="TkAboutContent__icon"
                                     height={114}></TkLazyImage>
                        <div className="TkAboutContent__description">
                            <br/>
                            <span>Ser líder nos mercados de atuação, referência em comércio especializado e <br/>
                  reconhecida pela excelência no atendimento, gestão, distribuição e logística.</span>
                        </div>
                    </div>
                </div>
                <div>
                    <TkLazyImage src={s3Bucket + "Norteador2.svg"} className="TkAboutContent__img"></TkLazyImage>
                </div>
                <p className="TkAboutContent__northDescription">
                    A excelência no atendimento ao cliente vem em primeiro lugar. Acreditamos no treinamento permanente
                    de nossas <br/>
                    equipes, aprimorando relacionamentos e nos especializando em produtos e suas melhores utilizações.
                    Buscamos a <br/>
                    integração com nossos fornecedores para realizar a entrega segura de soluções para cada tipo de
                    projeto, desde a escolha <br/>
                    de um equipamento adequado até a indicação de produtos de alta performance para obras e instalações.
                    Reunimos mais <br/>
                    de 50 mil itens entre materiais elétricos, hidráulicos, combate a incêndio, telefonia, ferramentas,
                    automação e segurança <br/>
                    produzidos pelas principais marcas do mercado, com produtos à pronta entrega e menor preço. Marcamos
                    nossa presença <br/>
                    oferecendo soluções para todos os segmentos: construção civil, indústria, comércio, serviços e
                    profissionais autônomos. <br/>
                    Estamos sempre disponíveis. Além do atendimento pessoal, estamos conectados 24 horas por dia com os
                    consumidores <br/>
                    na loja online, que possibilita uma compra ágil, intuitiva e segura, com meios de pagamento
                    flexíveis e personalizados. <br/>
                    Criar relações de confiança com o cliente é o que nos move.
                </p>
                <div className="TkAboutContent__grid">
                    <div className="TkAboutContent__iconName">Propósito</div>
                    <TkLazyImage src={s3Bucket + "Icon4.svg"} className="TkAboutContent__icon"
                                 height={114}></TkLazyImage>
                    <div className="TkAboutContent__description">
                        <br/>
                        <span>Cumprimos rigorosamente o compromisso assumido com nossos <br/>
                  clientes, entregando as melhores soluções para seus projetos, com <br/>
                  agilidade e segurança, contribuindo de forma relevante para a <br/>
                  qualidade e a eficiências de suas realizações.</span>
                    </div>
                </div>
                <div className="TkAboutContent__grid">
                    <div className="TkAboutContent__iconName">Estrutura</div>
                    <TkLazyImage src={s3Bucket + "Icon5.svg"} className="TkAboutContent__icon"
                                 height={114}></TkLazyImage>
                    <div className="TkAboutContent__description">
                        <br/>
                        <span>Estamos em constante crescimento. Em 2014, a sede atual da Plenobras foi ampliada e <br/>
                    reestruturada para oferecer mais espaço, agilidade de processos e melhoria dos fluxos de <br/>
                    logística, tornando-se o maior complexo de materiais elétricos e hidráulicos do Brasil. Contamos<br/>
                    com uma estrutura com mais de 25.000m2, localizada em área estratégica de Porto Alegre. A <br/>
                    inovação está sempre presente em nossos investimentos. Um novo sistema de compras online <br/>
                    está sendo preparado com todo cuidado para oferecer ao consumidor um ambiente digital <br/>
                    eficiente, acessível e seguro, com o objetivo de tornar a experiência valiosa e garantir a fidelização<br/>
                    às nossas marcas e serviços. O sistema de logística acompanha nosso crescimento, levando <br/>
                    nossa frota, com 10 caminhões e 6 veículos, todos próprios, a todas as cidades gaúchas e <br/>
                    garantindo entregas especiais para todo o Brasil, via aérea e rodoviária.</span>
                    </div>
                </div>
                <div className="TkAboutContent__grid">
                    <div className="TkAboutContent__iconName">Futuro</div>
                    <TkLazyImage src={s3Bucket + "Icon6.svg"} className="TkAboutContent__icon"
                                 height={114}></TkLazyImage>
                    <div className="TkAboutContent__description">
                        <br/>
                        <span>Marcar posição de liderança no mercado movimenta nossas ações e alimenta nossos <br/>
                    ideais. Ser referência em comércio especializado e reconhecida pela excelência no <br/>
                    atendimento, gestão, distribuição e logística é nossa visão. Nosso futuro está sendo <br/>
                    construído hoje para oferecer soluções inteligentes, produtos inovadores e marcar nossa <br/>
                    participação para o desenvolvimento de cidades mais eficientes e sustentáveis.</span>
                    </div>
                </div>
                <div>
                    <TkLazyImage src={s3Bucket + "Norteador3.svg"} className="TkAboutContent__img"></TkLazyImage>
                </div>
                <div>
                    <p className="TkAboutContent__northDescription">Oferecemos uma estrutura completa de atendimento e
                        um catálogo com milhares <br/>
                        de itens e soluções inovadoras das melhores marcas para atender a todos os tipos de clientes.
                    </p>
                    <div className="TkAboutContent__grid">
                        <div className="TkAboutContent__iconName">Industria</div>
                        <TkLazyImage src={s3Bucket + "Icon7New.svg"} className="TkAboutContent__icon"
                                     height={114}></TkLazyImage>
                        <div className="TkAboutContent__description">
                            <br/>
                            <span>Estamos sempre atentos às necessidades deste segmento, que busca uma orientação técnica <br/>
                    diferenciada, produtos de qualidade e agilidade nas entregas. Nossa equipe oferece atendimento <br/>
                    especializado e consultoria gratuita diretamente no seu parque fabril, auxiliando na seleção dos <br/>
                    melhores produtos e soluções elétricas e hidráulicas, além de garantir um projeto de eficiência <br/>
                    energética personalizado. Para qualificar ainda mais o atendimento das indústrias, oferecemos <br/>
                    outros dois serviços que garantem segurança e agilidade:<br/>
                    <br/>
                    <strong>Loja In Company:</strong> Uma loja Plenobras dentro de sua indústria, colocando à disposição de suas <br/>
                    equipes um estoque personalizado para atender suas demandas mais recorrentes;<br/>
                    <br/>
                    <strong>Rental:</strong> Aluguel de máquinas e equipamentos dentro das normas brasileiras para evitar paradas <br/>
                    na sua indústria, em caso de manutenções. Principais produtos: cabeamento, disjuntores de <br/>
                    média tensão, motores e bombas, linha de incêndio, comando e proteção, segurança de máquinas, <br/>
                    iluminações e painéis solares eficientes e adequados para ambientes industriais.<br/></span>
                        </div>
                    </div>
                    <div className="TkAboutContent__grid">
                        <div className="TkAboutContent__iconName">Construção Cívil</div>
                        <TkLazyImage src={s3Bucket + "Icon8.png"} className="TkAboutContent__icon"
                                     height={114}></TkLazyImage>
                        <div className="TkAboutContent__description">
                            <br/>
                            <span>Contribuímos com o segmento da Construção Civil, participando ativamente de todas as etapas <br/>
                    das obras de nossos clientes. Distribuímos as melhores marcas do mundo e reunimos o maior <br/>
                    estoque para pronta entrega do Sul do País, assumindo o compromisso de cumprir 100% dos <br/>
                    orçamentos realizados, no menor tempo possível, além de garantir agilidade e eficiência na <br/>
                    entrega.
                  <br/><br/>
                  Vamos além do fornecimento de produtos, prestando orientação especializada dos nossos <br/>
                    técnicos nas linhas de Eficiência Energética, solar, motores, inversores, disjuntores, hidráulica e <br/>
                    iluminação.
                  <br/><br/>
                  <strong>Principais produtos: </strong>tubos e conexões, acabamentos, válvulas, registros, louças, gás e ainda a <br/>
                  linha completa de incêndio, com acionadores, sprinklers, esguichos, mangueiras, sensores e <br/>
                  centrais com ou sem endereçamento. Também oferecemos nossa ampla gama de produtos <br/>
                  elétricos, com cabos à pronta entrega, conduletes, interruptores e tomadas, barramentos, quadros <br/>
                  de distribuição, eletrodutos e muito mais.</span>

                        </div>
                    </div>
                    <div className="TkAboutContent__grid">
                        <div className="TkAboutContent__iconName">Manutenção & <br/>Telecom</div>
                        <TkLazyImage src={s3Bucket + "Icon9.png"} className="TkAboutContent__icon"
                                     height={114}></TkLazyImage>
                        <div className="TkAboutContent__description">
                            <br/>
                            <span>Hospitais, universidades, condomínios e hotéis encontram aqui o que há de melhor em produtos <br/>
                    para a manutenção comercial e Telecom. São milhares de itens para pronta entrega e soluções <br/>
                    completas para garantir a qualidade dos serviços.<br/>
                    <br/><br/>
                    <strong>Principais produtos: </strong>Cabeamento, iluminação, disjuntores, interruptores e tomadas, ferramentas, <br/>
                    quadros de distribuição, louças, linha de incêndio e muito mais. Para os clientes que buscam <br/>
                    soluções em Telecom, como empresas, instaladoras e indústrias, oferecemos a pronta entrega de <br/>
                    itens como: cabeamento estruturado, conectores, patch cord, patch panel e racks.</span>
                        </div>
                    </div>
                    <div className="TkAboutContent__grid">
                        <div className="TkAboutContent__iconName">Energia <br/>Fotovoltaica</div>
                        <TkLazyImage src={s3Bucket + "Icon10.png"} className="TkAboutContent__icon"
                                     height={114}></TkLazyImage>
                        <div className="TkAboutContent__description">
                            <br/>
                            <span>Estamos alinhados com as novas tecnologias que contribuem para o desenvolvimento <br/>
                    sustentável, trabalhando em parceria com os melhores fornecedores do mercado para oferecer <br/>
                    aos nossos clientes soluções inovadoras para uso de energia fotovoltaica em suas residências, <br/>
                    empresas ou indústrias.
                    <br/><br/>
                    <strong>Principais produtos: </strong>Cabeamento, inversor de frequência, caixa de junção e módulos <br/>
                    fotovoltaicos.</span>
                        </div>
                    </div>
                    <div className="TkAboutContent__grid">
                        <div className="TkAboutContent__iconName">Combate a <br/>incêndio & <br/>Segurança</div>
                        <TkLazyImage src={s3Bucket + "Icon11.png"} className="TkAboutContent__icon"
                                     height={114}></TkLazyImage>
                        <div className="TkAboutContent__description">
                            <br/>
                            <span>A segurança das instalações e das equipes que trabalham nos projetos dos nossos clientes é uma <br/>
                    prioridade. Para melhor atender a este segmento, oferecemos um amplo catálogo de insumos <br/>
                    para combate a incêndio e de equipamentos de segurança individual (EPIs), além de toda a <br/>
                    orientação técnica necessária para que a segurança esteja presente em cada etapa de produção.</span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <TkNewsLetter/>
        <TkFooter/>
    </>
};

export default TkAboutView;
