import React from "react";
import {ITkProductModel, ITkProductVideoModel} from "../../models/product";
import TkProductAttributes from "../../components/product/TkProductAttributes";
import {isBlank} from "../../utils/string-utils";
import ProductBreadCrumb from "./_productBreadCrumb";
import ProductImageArea from "./_productImageArea";
import ProductTitleArea from "./_productTitleArea";
import ProductFloatInfoArea from "./_productFloatInfoArea";
import TkSkeleton from "../../components/particles/TkSkeleton";
import TkCarouselCartBasedProducts from "../../components/product/TkCarouselCartBasedProducts";
import TkCarouselSimilarityProducts from "../../components/product/TkCarouselSimilarityProducts";
import TkYoutubeLazyPlayer from "../../components/particles/TkYoutubeLazyPlayer";

const LargeScreen: React.FC<{
  product: ITkProductModel
  loadingProduct: boolean
  add: () => void
  setQuantity: (qtd: number) => void
  quantity: number
}> = ({product, loadingProduct, add, quantity, setQuantity}) => {
  return <>
    <div className="p-15px">
      <ProductBreadCrumb product={product}/>
    </div>

    <div className="TkProductDetailView__container">
      <div className="TkProductDetailView__container-1">
        <div>
          <ProductImageArea showSkeleton={loadingProduct} product={product}/>
          <div className="p-15px m-t-40px">
            <h3 className="t-a-c f-s-16px">{product?.videos?.length > 0 ? "Plenobras Experts" : "Quem Somos"}</h3>
            {product?.videos?.length > 0 ?
              product.videos.map((video: ITkProductVideoModel) => <TkYoutubeLazyPlayer videoUrl={video.url}/>)
              : <TkYoutubeLazyPlayer videoUrl="https://youtu.be/U1kcAPancaE"/>
            }
          </div>
        </div>
        <div>
          <ProductTitleArea
            showSkeleton={loadingProduct}
            add={add}
            quantity={quantity}
            setQuantity={setQuantity}
            product={product}
          />

          {loadingProduct && (
            <div className="row m-t-40px">
              <div className="col-lg-12">
                <h2 className="t-a-c f-s-16px">
                  <TkSkeleton
                    width="30%"
                    height={24}
                    style={{display: "inline-block"}}
                  />
                </h2>
                <TkProductAttributes attributes={[]} showSkeleton={true}/>
              </div>
            </div>
          )}

          {product && (product.technicalDetails || product.additionalInfos) && (
            <div className="row m-t-40px">
              {product.technicalDetails && (
                <div className="col-lg-6">
                  <h2 className="t-a-c f-s-16px">Detalhes técnicos</h2>
                  {!isBlank(product.technicalDetails) && (
                    <p>{product.technicalDetails}</p>
                  )}
                </div>
              )}

              {!isBlank(product.additionalInfos) && (
                <div className="col-lg-6">
                  <h2 className="t-a-c f-s-16px">
                    Informações complementares
                  </h2>
                  <p>{product.additionalInfos}</p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <ProductFloatInfoArea
        add={add}
        quantity={quantity}
        setQuantity={setQuantity}
        product={product}
        showSkeleton={loadingProduct}
      />
    </div>

    <div className="TkProductDetailView__recommender-container">
      <TkCarouselSimilarityProducts externalId={product?.externalId}/>

      <TkCarouselCartBasedProducts productId={product?._id}/>
    </div>
  </>

};
export default LargeScreen;
