import {ITkProductModel} from "./product";
export enum BannerDeviceType {
    MOBILE = 'MOBILE',
    DESKTOP = 'DESKTOP'
}

export interface ITkManufacturerSitePageModel {
    _id?: string;
    banners?: ITkBannerModel[];
    mainColor?: string;
    logoUrl?: string;
    videoUrl?: string;
    presentationText?: string;
    manufacturer?: ITkProductManufacturerModel;
    slug?: string;
    popup?: {
        delay?: number,
        link?: string,
        imageUrl?: string,
    };
    highlightProducts?: ITkProductModel[];
    isActive?: boolean;
    isRemoved?: boolean;
    createdAt?: Date;
    updatedAt?: Date;
}

export interface ITkProductManufacturerModel {
    _id?: string
    name?: string
}

export interface ITkBannerModel {
    _id?: string;
    url: string;
    deviceType?: BannerDeviceType;
    isActive?: boolean;
}