import React, {FC, useEffect, useMemo, useState} from 'react';
import {Redirect, Route, RouteProps, Router, Switch} from 'react-router-dom';
import history from "./utils/history-utils";
import TkProvider, {useTkMedia, useTkUser} from "./context/TkContext";
import {
    Tk401View,
    Tk404View,
    Tk500View,
    TkAboutView,
    TkBeInTouchView,
    TkChangePasswordView,
    TkCheckoutView,
    TkExchangesAndDevolutionView,
    TkExpertsView,
    TkForgotPasswordView,
    TkHomeView,
    TkItemAddedToCartView,
    TkMakeDeakOrderView,
    TkMyAccountView,
    TkMyAddressesView,
    TkMyAddressView,
    TkMyCartView,
    TkMyCompaniesView,
    TkMyCompanyView,
    TkMyDataView,
    TkMyListsView,
    TkMyOrdersView,
    TkMyTypeView,
    TkPricePolicyView,
    TkPrivacyPolicyView,
    TkProductDetailView,
    TkProView,
    TkReceiveListView,
    TkSearchView,
    TkSellerInfoView,
    TkSellerListsManagerView,
    TkShopkeeperAndPartnersView,
    TkSignInView,
    TkSignUpView,
    TkWithdrawPolicyView
} from './views';
import DeviceStorage, {getAuthToken} from "./utils/storage-utils";
import {pageView} from "./utils/analytics-utils";
import TkWhatsAppBalloon from "./components/particles/TkWhatsAppBalloon";
import {ToastContainer, ToastOptions} from 'react-toastify';
import {UserRole} from "./models/user";

const PrivateRoute: FC<{ path: string, exact?: boolean }> = ({children, ...rest}) => {
    return <Route
        {...rest}
        render={({location}) =>
            getAuthToken() || DeviceStorage.user ? children
                : <Redirect
                    to={{
                        pathname: TkSignInView.tk_route_sign_in,
                        search: `from=${encodeURIComponent(location.pathname)}`,
                        state: {from: location}
                    }}
                />
        }
    />
};

const ProtectedRoute: FC<RouteProps & {role: UserRole}> = ({children, role, ...rest}) => {
    const {hasRole} = useTkUser()
    const [isAuthorized, setAuthorized] = useState(false)
    const [isVerifying, setVerifying] = useState(true)

    useEffect(() => {
        setVerifying(true)
        hasRole(role)
            .then(setAuthorized)
            .finally(() => setVerifying(false))
    }, [role]);


    return <Route {...rest} render={({location}) => {
        if (isVerifying) return null
        if (isAuthorized) return children

        return <Redirect to={{
            pathname: Tk401View.tk_route_401,
            search: `from=${encodeURIComponent(location.pathname)}`,
            state: {from: location}
        }}/>
    }}/>

}

const TkToastContainer:FC = () => {
    const {isMediaXs, isMediaSm} = useTkMedia()
    const toastOptions = useMemo<ToastOptions>(() => {
        return {
            position: isMediaXs || isMediaSm ? 'bottom-center': 'top-right',
            closeButton: !(isMediaXs || isMediaSm),
            autoClose: 3000,
        }
    }, [isMediaSm, isMediaXs])
    return <ToastContainer {...toastOptions}/>
}

const App: React.FC = () => {
    useEffect(() => {
        pageView(`${history.location.pathname}${history.location.search}`);

        return history.listen(location => pageView(`${history.location.pathname}${history.location.search}`));
    }, [])

    return <>
        <TkProvider>
            <TkToastContainer/>
            <Router history={history}>
                <Switch>
                    <PrivateRoute path={TkCheckoutView.tk_route_checkout}>
                        <TkCheckoutView.default/>
                    </PrivateRoute>
                    <PrivateRoute exact={true} path={TkMyAddressesView.tk_route_my_addresses()}>
                        <TkMyAddressesView.default/>
                    </PrivateRoute>
                    <PrivateRoute exact={true} path={TkMyAddressView.tk_route_address()}>
                        <TkMyAddressView.default/>
                    </PrivateRoute>
                    <PrivateRoute exact={true} path={TkMyOrdersView.tk_route_my_orders}>
                        <TkMyOrdersView.default/>
                    </PrivateRoute>
                    <PrivateRoute exact={true} path={TkMyDataView.tk_route_my_data}>
                        <TkMyDataView.default/>
                    </PrivateRoute>
                    <PrivateRoute exact={true} path={TkMyListsView.tk_route_my_lists}>
                        <TkMyListsView.default/>
                    </PrivateRoute>
                    <PrivateRoute exact={true} path={TkMyCompaniesView.tk_route_companies}>
                        <TkMyCompaniesView.default/>
                    </PrivateRoute>
                    <PrivateRoute exact={true} path={TkMyCompanyView.tk_route_company()}>
                        <TkMyCompanyView.default/>
                    </PrivateRoute>
                    <PrivateRoute exact={true} path={TkMyAccountView.tk_route_account}>
                        <TkMyAccountView.default/>
                    </PrivateRoute>
                    <PrivateRoute exact={true} path={TkMyTypeView.tk_route_my_type}>
                        <TkMyTypeView.default/>
                    </PrivateRoute>
                    <PrivateRoute path={TkMakeDeakOrderView.tk_route_deak_order()}>
                        <ProtectedRoute role={UserRole.PLENOBRAS_SELLER_MAKE_ORDER}>
                            <TkMakeDeakOrderView.default/>
                        </ProtectedRoute>
                    </PrivateRoute>
                    <PrivateRoute exact={true} path={TkSellerListsManagerView.tk_route_seller_list_manager_view()}>
                        <ProtectedRoute role={UserRole.PLENOBRAS_SELLER_LIST_MANAGER}>
                            <TkSellerListsManagerView.default/>
                        </ProtectedRoute>
                    </PrivateRoute>
                    <Route path={TkHomeView.tk_route_root} exact>
                        <TkHomeView.default/>
                    </Route>
                    <Route path={TkItemAddedToCartView.tk_route_item_added_to_cart()}>
                        <TkItemAddedToCartView.default/>
                    </Route>
                    <Route path={TkMyCartView.tk_route_my_cart}>
                        <TkMyCartView.default/>
                    </Route>
                    <Route exact path={TkSellerInfoView.tk_route_seller}>
                        <TkSellerInfoView.default/>
                    </Route>
                    <Route exact path={TkProductDetailView.tk_route_product_detail()}>
                        <TkProductDetailView.default/>
                    </Route>
                    <Route path={TkSignInView.tk_route_sign_in}>
                        <TkSignInView.default/>
                    </Route>
                    <Route path={TkSignUpView.tk_route_sign_up}>
                        <TkSignUpView.default/>
                    </Route>
                    <Route path={TkForgotPasswordView.tk_route_forget_pass}>
                        <TkForgotPasswordView.default/>
                    </Route>

                    <Route path={TkChangePasswordView.tk_route_change_password}>
                        <TkChangePasswordView.default/>
                    </Route>

                    <Route path={TkAboutView.tk_route_about}>
                        <TkAboutView.default/>
                    </Route>
                    <Route path={TkBeInTouchView.tk_route_be_in_touch}>
                        <TkBeInTouchView.default/>
                    </Route>
                    <Route path={TkExchangesAndDevolutionView.tk_route_exchanges_and_devolution}>
                        <TkExchangesAndDevolutionView.default/>
                    </Route>
                    <Route path={TkExpertsView.tk_route_experts}>
                        <TkExpertsView.default/>
                    </Route>
                    <Route path={TkProView.tk_route_pro}>
                        <TkProView.default/>
                    </Route>
                    <Route path={TkPrivacyPolicyView.tk_route_privacy_policy}>
                        <TkPrivacyPolicyView.default/>
                    </Route>
                    <Route path={TkPricePolicyView.tk_route_price_policy}>
                        <TkPricePolicyView.default/>
                    </Route>
                    <Route path={TkShopkeeperAndPartnersView.tk_route_shopkeeper_and_partners}>
                        <TkShopkeeperAndPartnersView.default/>
                    </Route>
                    <Route path={TkWithdrawPolicyView.tk_route_withdraw_policy}>
                        <TkWithdrawPolicyView.default/>
                    </Route>
                    <Route path={TkSearchView.tk_route_search}>
                        <TkSearchView.default/>
                    </Route>
                    <Route path={TkSearchView.tk_route_manufacturer_page}>
                        <TkSearchView.default isShowManufacturerPage={true}/>
                    </Route>
                    <Route path={TkReceiveListView.tk_route_receive_list}>
                        <TkReceiveListView.default/>
                    </Route>
                    <Route path={Tk500View.tk_route_something_wrong}>
                        <Tk500View.default/>
                    </Route>
                    <Route path={Tk401View.tk_route_401}>
                        <Tk401View.default/>
                    </Route>
                    <Route path={Tk404View.tk_route_404}>
                        <Tk404View.default/>
                    </Route>
                </Switch>
            </Router>
            <TkWhatsAppBalloon/>
        </TkProvider>
    </>;
};

export default App;
