import {httpPostGraphQL} from "../../utils/http-utils";
import {getAllManufacturerSitePagesQuery, getManufacturerSitePageByManufacturerQuery} from "./queries";
import {ITkManufacturerSitePageModel} from "../../models/manufacturerSitePage";

export interface TkManufacturerSitePageContextType {
    getManufacturerSitePageByManufacturer: (manufacturerId: string) => Promise<ITkManufacturerSitePageModel>
    getAllManufacturerSitePages: () => Promise<ITkManufacturerSitePageModel[]>
}

const TkManufacturerSitePageContext = (): TkManufacturerSitePageContextType => {
    const getAllManufacturerSitePages = async () => {
        try {
            const {data: result} = await httpPostGraphQL({
                query: getAllManufacturerSitePagesQuery,
            })

            if (result.errors) return Promise.reject(result.errors)

            return result.data.getAllManufacturerSitePages
        } catch (e) {
            console.error('Falha ao consultar páginas dos fabricantes',e)
            return Promise.reject(e)
        }
    }

    const manufacturerCache = new Map<string, ITkManufacturerSitePageModel>()

    const getManufacturerSitePageByManufacturer = async (manufacturerId: string) => {
        try {
            if (manufacturerCache.has(manufacturerId)) return manufacturerCache.get(manufacturerId)

            const {data: result} = await httpPostGraphQL({
                query: getManufacturerSitePageByManufacturerQuery,
                variables: {manufacturerId: manufacturerId}
            })

            if (result.errors) return Promise.reject(result.errors)

            const manufacturerSitePage = result.data.getManufacturerSitePageByManufacturer

            manufacturerCache.set(manufacturerId, manufacturerSitePage)
            return manufacturerSitePage
        } catch (e) {
            console.error('Falha ao consultar páginas dos fabricantes',e)
            return Promise.reject(e)
        }
    }

    return {
        getAllManufacturerSitePages,
        getManufacturerSitePageByManufacturer,
    }
}

export default TkManufacturerSitePageContext