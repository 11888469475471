import React, {forwardRef, ForwardRefRenderFunction, useImperativeHandle, useState} from "react";
import {isBlank} from "../../utils/string-utils";
import {Link} from "react-router-dom";

export interface ITkManufacturerPagePopup {
    close: () => void;
    open: () => void;
}

const _ManufacturerPagePopup: ForwardRefRenderFunction<ITkManufacturerPagePopup, {
    imageUrl: string;
    link?: string;
    onClose?: () => void;
}> = ({link, imageUrl, onClose}, ref, ) => {
    const [isVisible, setIsVisible] = useState(false);

    const image = <img
        src={imageUrl}
        alt="Imagem da Popup"
    />

    const close = () => {
        setIsVisible(false)

        if(onClose) onClose()
    }

    useImperativeHandle(ref, () => {
        return {
            open: () => setIsVisible(true),
            close: () => close(),
        };
    });

    return <div className={`TkSearchView__popup-container ${isVisible ? 'visible' : ''}`} onClick={close}>
        <div className="TkSearchView__popup-content">
            <button className="TkSearchView__close-btn" onClick={() => close()}>
                &times;
            </button>
            {!isBlank(link) ?
                <Link to={link}>
                    {image}
                </Link>
                : image
            }
        </div>
    </div>;
}

export default forwardRef(_ManufacturerPagePopup);