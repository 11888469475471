const primaryColor = '--primary-color';

const getCssColor = (cssVariableName: string) => getComputedStyle(document.documentElement)
    .getPropertyValue(cssVariableName);

const getContrastColor = (bgColor: string) => {
    if (bgColor.startsWith("#")) {
        bgColor = bgColor.slice(1);
    }

    let r = parseInt(bgColor.substring(0, 2), 16) / 255;
    let g = parseInt(bgColor.substring(2, 4), 16) / 255;
    let b = parseInt(bgColor.substring(4, 6), 16) / 255;

    const luminance = (c: number) => {
        return c <= 0.03928 ? c / 12.92 : Math.pow((c + 0.055) / 1.055, 2.4);
    }

    const L = 0.2126 * luminance(r) + 0.7152 * luminance(g) + 0.0722 * luminance(b);
    return L > 0.5 ? "#000000" : "#FFFFFF";
}

const tkPrimaryColor = getCssColor(primaryColor);

export {
    tkPrimaryColor,
    getContrastColor
}